import { ELoanPurpose } from '@src/types/constants'

export const MEDICARD_ID = '20878'
export const IFINANCE_ID = '20500'
export const IFINANCE_PAY_ID = '22118'

export function extractNumbersFromMerchantId(merchantId: string) {
  const parts = merchantId.split('alis*').filter((x) => x.length > 0)
  return parts[0]
}

export function isMedicardMerchant(merchantId: string) {
  const id = extractNumbersFromMerchantId(merchantId)
  return id === MEDICARD_ID
}

export function isIFinanceMerchant(merchantId: string) {
  const id = extractNumbersFromMerchantId(merchantId)
  return id === IFINANCE_ID
}

export function isDefaultMerchant(merchantId: string) {
  const id = extractNumbersFromMerchantId(merchantId)
  return id === IFINANCE_ID || id === MEDICARD_ID
}
export function isMedicardLoanPurpose(loanPurposeId: ELoanPurpose | null | undefined) {
  return loanPurposeId === ELoanPurpose.Medical || loanPurposeId === ELoanPurpose.Aesthetics
}
