import { FilteredCreditApplication } from '@src/api/credit-api'
import BackButton from '@src/components/BackButton'
import UploadFilesTable from '@src/components/UploadFilesTable'

type Props = {
  creditApp: FilteredCreditApplication
}

const DocumentsPage = ({ creditApp }: Props) => {
  return (
    <main className="general-message">
      <UploadFilesTable creditApp={creditApp} />
      <BackButton />
    </main>
  )
}

export default DocumentsPage
