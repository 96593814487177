import { EOriginSystemId, FilteredCreditApplication } from '@src/api/credit-api'
import {
  applicantFullName,
  hasBankSatementsUnderReviewOrWaitingForBank,
  hasApplicantSubmittedAllRequiredIncomeDocuments,
} from '@src/data/credit-application-selectors'
import { EApplicantType, RequiredDocumentWithStatus } from '@src/types'
import { useTranslation } from 'react-i18next'
import CreditStep, { CreditStepStatus } from './CreditStep'

type IncomeCreditStepProps = {
  creditApp: FilteredCreditApplication
  applicantType: EApplicantType
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}

const IncomeCreditStep = ({ creditApp, applicantType, skipBankAccountRequiredDocuments }: IncomeCreditStepProps) => {
  const { t } = useTranslation()
  const applicant = applicantType === EApplicantType.Applicant ? creditApp.applicant : creditApp.coapplicant!
  const title = t('overview.incomeStep', { name: applicantFullName(applicant) })

  const areIncomeDocsSubmitted = hasApplicantSubmittedAllRequiredIncomeDocuments(
    skipBankAccountRequiredDocuments,
    applicantType,
  )

  const bankStatementUnderReviewOrWaitingForBank = hasBankSatementsUnderReviewOrWaitingForBank(creditApp, applicantType)

  const areIncomePendingReview =
    !applicant.areIncomesConfirmed && (areIncomeDocsSubmitted || bankStatementUnderReviewOrWaitingForBank) // && !hasApplicantFinishedFlinks

  const status =
    applicant.areIncomesConfirmed || areIncomePendingReview
      ? CreditStepStatus.Completed
      : CreditStepStatus.RequiresAttention

  const description =
    applicant.hasSkippedIncomeValidation && applicant.areIncomesConfirmed
      ? 'overview.incomeSkipped'
      : applicant.areIncomesConfirmed
        ? 'overview.incomeConfirmed'
        : areIncomePendingReview
          ? 'overview.incomeReview'
          : 'overview.incomeVerify'

  return (
    <CreditStep
      status={status}
      noConnector={creditApp.originSystemId === EOriginSystemId.MerchantDashboard}
      linkToDetail={`${applicantType.toLowerCase()}-incomes`}
      hideLinkToDetail={status === CreditStepStatus.Completed}
      title={title}
      content={t(description)}
    />
  )
}

export default IncomeCreditStep
