import translate from '@src/services/translate'
import { translateErrorCode } from '@src/types'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'i18next/typescript/helpers'
import React, { LegacyRef } from 'react'
import { FieldError } from 'react-hook-form'

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  id: string
  label: string
  type?: 'text' | 'number'
  error?: FieldError
  wrapStyle?: string
  errorParams?: TOptionsBase & $Dictionary
}

const InputText = React.forwardRef<Props, Props>(
  ({ id, error, label, wrapStyle, type = 'text', errorParams, ...otherProps }: Props, ref) => {
    return (
      <div className={`control-group ${error && 'error'}`}>
        <label htmlFor={id}>{translate(label)}</label>
        <div className={wrapStyle}>
          <input id={id} type={type} {...otherProps} ref={ref as LegacyRef<HTMLInputElement>} />
        </div>
        {error && <p className="error-message">{translateErrorCode(error, errorParams)}</p>}
      </div>
    )
  },
)

export default InputText
