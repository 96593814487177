import { MutationFunction, QueryFunctionContext, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'feedback'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

export type CustomerFeedback = {
  withdrawalReasonId: string | null
  contactClient: boolean
  isSatisfied: boolean
  badReviewReasonId: string | null
}

const getFeedback = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  try {
    const response = await apiClient.get<CustomerFeedback>(
      `/api/creditApplication/${encodeURIComponent(id)}/CustomerFeedback`,
    )
    return response.data
  } catch (e) {
    return null
  }
}

export function useGetFeedback(creditApplicationId: string): [CustomerFeedback | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getFeedback,
    enabled: !!creditApplicationId,
  })

  return [data ?? null, isFetching]
}

export type CustomerFeedbackDto = CustomerFeedback & {
  creditApplicationId: string
}

const submitFeedback: MutationFunction<CustomerFeedbackDto, CustomerFeedbackDto> = async (dto: CustomerFeedbackDto) => {
  const response = await apiClient.post(`api/creditApplication/${dto.creditApplicationId}/CustomerFeedback`, dto)
  const ret = response.data as CustomerFeedbackDto
  ret.creditApplicationId = dto.creditApplicationId
  return ret
}

export function useSubmitFeedback(): [MutationFunction<CustomerFeedbackDto, CustomerFeedbackDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: submitFeedback,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.creditApplicationId), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}
