import { Path, UseFormTrigger } from 'react-hook-form'
import { AssertsShape, ObjectShape } from 'yup/lib/object'
import { reportErrorToConsole } from './error-logger'

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const setYearMonthValueFromMonths = (
  months: number,
  yearSelect: HTMLSelectElement,
  monthSelect: HTMLSelectElement,
) => {
  if (months) {
    yearSelect.value = ''
    monthSelect.value = ''
    const year = Math.trunc(months / 12)
    const month = months % 12
    if (year > 0) yearSelect.value = year.toString()
    else yearSelect.selectedIndex = 0

    if (month > 0) monthSelect.value = month.toString()
    else monthSelect.selectedIndex = 0
  }
}

export const phoneFormat = (phone: string) => {
  if (!phone) return phone
  let formatedInputVal = phone.replace(/\D/g, '')
  const size = formatedInputVal.length

  if (size > 0) {
    formatedInputVal = `(${formatedInputVal.slice(0)}`
  }
  if (size > 3) {
    formatedInputVal = `${formatedInputVal.slice(0, 4)}) ${formatedInputVal.slice(4)}`
  }
  if (size > 6) {
    formatedInputVal = `${formatedInputVal.slice(0, 9)}-${formatedInputVal.slice(9)}`
  }
  if (size > 10) {
    formatedInputVal = `${formatedInputVal.slice(0, 14)}`
  }
  return formatedInputVal
}

export const removePhoneFormat = (phoneFormated: string) => {
  let phone = phoneFormated.replace(' ', '')
  phone = phone.replace('(', '')
  phone = phone.replace(')', '')
  phone = phone.replace('-', '')

  return phone
}

export const sinFormat = (sin: string) => {
  if (!sin) return sin
  let formatedInputVal = sin.replace(/\D/g, '')
  const size = formatedInputVal.length
  if (size > 3) {
    formatedInputVal = `${formatedInputVal.slice(0, 3)}-${formatedInputVal.slice(3)}`
  }
  if (size > 6) {
    formatedInputVal = `${formatedInputVal.slice(0, 7)}-${formatedInputVal.slice(7)}`
  }
  if (size > 9) {
    formatedInputVal = `${formatedInputVal.slice(0, 11)}`
  }
  return formatedInputVal
}

export const removeSinFormat = (sinFormated: string) => {
  return sinFormated.replace(/-/g, '')
}

export const getValuesFromObject = (keys: string[], values: { [k: string]: unknown }) => {
  const obj: { [k: string]: unknown } = {}
  keys.forEach((key) => {
    obj[key] = values[key]
  })
  return obj
}

export function validateObjectValue<T extends ObjectShape>(
  object: AssertsShape<T>,
  trigger: UseFormTrigger<AssertsShape<T>>,
) {
  const validate = (field: string) => trigger(field as Path<AssertsShape<T>>)

  const validateChildObject = (parentKey: string, value: Record<string, unknown>) => {
    Object.entries(value).forEach(([childKey, childValue]) => {
      if (childValue) {
        if (typeof childValue !== 'object') validate(`${parentKey}.${childKey}`).catch(reportErrorToConsole)
        else if (typeof childValue === 'object')
          setTimeout(() => validateChildObject(`${parentKey}.${childKey}`, childValue as Record<string, unknown>), 0)
      }
    })
  }

  Object.entries(object).forEach(([key, value]) => {
    if (value) {
      if (typeof value !== 'object') validate(key).catch(reportErrorToConsole)
      else if (typeof value === 'object') validateChildObject(key, value as Record<string, unknown>)
    }
  })
}

// Phone Input
export const formatPhonesNumber = () => {
  const phones = document.querySelectorAll('.phone-input')

  function handleInput(this: HTMLInputElement) {
    this.value = phoneFormat(this.value)
  }

  phones.forEach((elem) => {
    elem.addEventListener('input', handleInput)
  })
}

// SIN Input
export const formatSIN = () => {
  const sin = document.querySelectorAll('.sin-input')

  function handleInput(this: HTMLInputElement) {
    this.value = sinFormat(this.value)
  }

  sin.forEach((elem) => {
    elem.addEventListener('input', handleInput)
  })
}

// Tooltip Adjustment
export const helpTip = () => {
  const helpTips = document.querySelectorAll('.help-tip')

  function handleMouseenter(this: HTMLInputElement) {
    const tooltip = this.querySelector('p')
    if (tooltip) {
      const tooltipWidth = tooltip.offsetWidth
      const tooltipOffset = this.getBoundingClientRect().left
      const viewportWidth = window.innerWidth

      if (tooltipOffset + tooltipWidth > viewportWidth) {
        tooltip.style.left = `${viewportWidth - tooltipOffset - tooltipWidth - 20}px`
        tooltip.classList.add('no-arrow') // Add a class to hide the arrow
      }
    }
  }

  function handleMouseleave(this: HTMLInputElement) {
    const tooltip = this.querySelector('p')
    if (tooltip) {
      tooltip.style.left = '-0.4rem'
      tooltip.classList.remove('no-arrow')
    }
  }

  helpTips.forEach((element) => {
    element.addEventListener('mouseenter', handleMouseenter)
    element.addEventListener('mouseleave', handleMouseleave)
  })
}

export const scrollToTop = () => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

const convertStringToArrayBuffer = (str: string) => {
  const textEncoder = new TextEncoder()
  return textEncoder.encode(str).buffer
}
export function convertFileToArrayBuffer(file: File): Promise<ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    if (!file || !file.name) {
      reject(new Error('Invalid or missing file.'))
    }

    const reader = new FileReader()

    reader.onload = () => {
      const arrayBuffer: ArrayBuffer | null | string = reader.result

      if (arrayBuffer === null) {
        resolve(null)
        return
      }
      if (typeof arrayBuffer === 'string') {
        resolve(convertStringToArrayBuffer(arrayBuffer))
        return
      }
      if (!arrayBuffer) {
        reject(new Error('Failed to read file into ArrayBuffer.'))
        return
      }

      resolve(arrayBuffer)
    }

    reader.onerror = () => {
      reject(new Error('Error reading file.'))
    }

    reader.readAsArrayBuffer(file)
  })
}

export const replaceWithNonBreakingHyphen = (hyphenatedString: string) => {
  return hyphenatedString.replaceAll('-', '\u2011')
}

export const replaceLastSpaceWithNbsp = (str: string) => {
  return str.replace(/\s(?=\S*$)/, '\u00A0')
}
