import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { I18nextProvider } from 'react-i18next'
import initializeFPixel from './FPixel'
import initializeGAnalytics from './GAnalytics'
import i18n from './api/i18n-api'
import App from './containers/App/App'
import './containers/App/App.css'
import './index.css'
import initializeAppInsights from './services/AppInsights'
import { setupTracking } from './services/error-logger'

const ai = initializeAppInsights()
setupTracking(ai)

initializeGAnalytics()

initializeFPixel()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <App />
        <div
          style={{
            fontSize: '16px',
          }}
        >
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
        </div>
      </QueryClientProvider>
    </I18nextProvider>
  </GoogleReCaptchaProvider>,
)
