import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  icon: string
  label: string
  position: number
  selected?: boolean
  invalid?: boolean
  disabled?: boolean
}

const StepperHeaderStep = ({ icon, label, position, selected = false, invalid = false, disabled = false }: Props) => {
  const { t } = useTranslation()

  const className = useMemo(() => {
    let cn = 'stepper__step'
    if (selected) cn += ' stepper__step--selected'
    if (invalid) cn += ' stepper__step--invalid'
    if (disabled) cn += ' stepper__step--disabled'
    return cn
  }, [disabled, invalid, selected])

  return (
    <li className={className}>
      <button type="button" className="stepper__step__btn" disabled={disabled}>
        <i className={`fa-light fa-${icon}`} />
        <div className="stepper__step__info">
          <p>
            {t('common.step')} {position}
          </p>
          <h4>{label}</h4>
        </div>
      </button>
    </li>
  )
}

export default StepperHeaderStep
