import { AppContext, useAppStoreProvider } from '@src/data/AppContext'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import { OriginationTracking } from '@src/types/origination'
import { frCA, enCA } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'
import TopLevelRoutes from './Routes'
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop'

const App = (): JSX.Element => {
  const { i18n } = useTranslation()

  let origination: OriginationTracking | null = null
  const searchParams = new URLSearchParams(window.location.search)
  const sourceId = searchParams.get('sourceId')
  const campaignId = searchParams.get('campaignId')
  const mediumId = searchParams.get('mediumId')
  if (sourceId && campaignId && mediumId) {
    origination = { sourceId, campaignId, mediumId }
  }

  if (i18n.resolvedLanguage === 'fr') setDefaultOptions({ locale: frCA })
  else if (i18n.resolvedLanguage === 'en') setDefaultOptions({ locale: enCA })

  const appStore = useAppStoreProvider(origination)

  return (
    <AppContext.Provider value={appStore}>
      <Router>
        <ScrollToTop />
        <Header language={i18n.language} />
        <TopLevelRoutes />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Router>
    </AppContext.Provider>
  )
}

export default App
