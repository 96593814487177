import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

type Props = {
  creditAppId: string
}

const CancelAppButton = ({ creditAppId }: Props) => {
  const { t } = useTranslation()

  return (
    <Link to={`/creditApplication/${creditAppId}/cancel`} className="btn btn-underline">
      {t('common.cancelMyApp')}
    </Link>
  )
}

export default CancelAppButton
