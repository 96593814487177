import { Trans, useTranslation } from 'react-i18next'
import { FormatCurrency } from '@src/services/Formatter'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { useEffect, useMemo } from 'react'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { ErrorPage, ExpandableSection } from '@src/components'
import useTermPaymentsTable from '@src/containers/ViewCreditApplication/InitialApprobationPage/hooks'

type Props = {
  creditApp: FilteredCreditApplication
  soonestDeliveryOn: Date
  setStepState: StepStateUpdater
  setNext: () => void
}

const InsuranceStep = ({ creditApp, soonestDeliveryOn, setStepState, setNext }: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext<WorksheetForm>()

  const {
    field: { onChange, onBlur },
  } = useController({ control, name: 'includeInsurance' })

  const { amountRequested, firstPaymentOn, term } = useWatch({ control })

  const [monthlyPaymentPerMonths, isLoadingTerms] = useTermPaymentsTable(creditApp, {
    firstPaymentOn,
    activationDate: soonestDeliveryOn,
    requestedAmount: Number(amountRequested),
    includeInsurance: true,
  })

  const selectedTerm = useMemo(
    () => monthlyPaymentPerMonths.find((mp) => mp.term === term),
    [monthlyPaymentPerMonths, term],
  )

  useEffect(() => setStepState({ loading: isLoadingTerms }), [isLoadingTerms, setStepState])

  if (isLoadingTerms) return <div className="loader" />
  if (!selectedTerm) return <ErrorPage />

  return (
    <div className="alert info" style={{ paddingTop: '48px', paddingBottom: '48px' }}>
      <h3 style={{ marginBottom: '32px' }}>
        <Trans
          i18nKey="worksheet.protection.title"
          values={{ insuranceAmount: FormatCurrency(selectedTerm.insuranceDelta) }}
        />
      </h3>
      <p style={{ marginBottom: '32px' }}>
        <Trans i18nKey="worksheet.protection.description" />
      </p>
      <p className="strong" style={{ marginBottom: '32px' }}>
        {t('worksheet.protection.subtitle')}
      </p>
      <ExpandableSection toggleText={t('worksheet.protection.learn_more.label')}>
        <h4 style={{ marginBottom: '16px', marginTop: '8px' }}>{t('worksheet.protection.learn_more.title')}</h4>
        <p style={{ marginBottom: '16px' }}>{t('worksheet.protection.learn_more.description')}</p>
        <div className="caption">{t('worksheet.protection.learn_more.caption')}</div>
      </ExpandableSection>
      {!selectedTerm.effectiveRateWithInsuranceIsLegal && (
        <div className="error-message">{t('worksheet.protection.ineligible')}</div>
      )}
      <div
        className="btn-group mobile-reversed full-width-mobile"
        style={{ display: 'flex', justifyContent: 'space-between', gap: '8px', marginTop: '32px' }}
      >
        <button
          className="btn secondary-action-btn btn-small"
          onClick={() => {
            onChange(false)
            onBlur()
            setNext()
          }}
        >
          {t('worksheet.protection.no')}
        </button>
        <button
          className="btn btn-blue btn-small"
          disabled={!selectedTerm.effectiveRateWithInsuranceIsLegal}
          onClick={() => {
            onChange(true)
            onBlur()
            setNext()
          }}
        >
          {t('worksheet.protection.yes', { insuranceAmount: FormatCurrency(selectedTerm.insuranceDelta) })}
        </button>
      </div>
    </div>
  )
}

export default InsuranceStep
