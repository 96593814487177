import { Address } from '@src/containers/SubmitNewApp/components/PrequalificationSchema'
import i18next from 'i18next'

export function FormatCurrency(data?: number | string | null | undefined, decimals = 2) {
  const { language } = i18next
  let amount = 0
  if (data) {
    amount = parseFloat(data.toString())
  }
  return amount
    .toLocaleString(`${language}-CA`, {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .replace(' ', '\u202F')
}

export function FormatCurrencyNoDecimals(data?: number | null) {
  return FormatCurrency(data, 0)
}

export function FormatCurrencyToNearestLowerMultipleToNoDecimals(data: number, roundDownTo: number) {
  let amount = Math.floor(data)
  const remainder = amount % roundDownTo

  if (remainder !== 0) {
    amount -= remainder
  }

  return FormatCurrency(amount, 0)
}

export function FormatCurrencyToNearestUpperMultipleToNoDecimals(data: number, roundUpTo: number) {
  const amount = Math.ceil(data / roundUpTo) * roundUpTo
  return FormatCurrency(amount, 0)
}

export function FormatCurrencyRoundedUpToNoDecimals(data: number) {
  const amount = Math.ceil(data)
  return FormatCurrency(amount)
}

export function formatNumber(data?: number | null | undefined) {
  const { language } = i18next
  const val = data?.toLocaleString(`${language}-CA`, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  return val !== undefined && val !== null ? val : ''
}

export const formatDateTime = (date: Date | null | undefined) =>
  !date ? '' : new Date(date).toLocaleString('en-CA', { hour12: true })

export const formatIsoDate = (date: Date) => {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}

export const formatTime = (date: Date | null | string | undefined) => {
  if (!date) return ''
  let dateString = date.toString()

  if (dateString.split('T') && dateString.split('T').length === 1) dateString = dateString.concat('T00:00:00')
  if (Date.parse(dateString)) return new Date(dateString).toLocaleTimeString('en-CA')

  return date
}
export const formatDate = (date: Date | null | string | undefined): string => {
  if (!date) return ''
  let dateString = date.toString()

  if (dateString.split('T') && dateString.split('T').length === 1) dateString = dateString.concat('T00:00:00')
  if (Date.parse(dateString)) return new Date(dateString).toLocaleDateString('en-CA')

  return date.toString()
}

export const formatAddress = (address: Address) => {
  if (!address) return 'N/A'
  let ret = ''
  if (address.civicNumber) ret = ret.concat(address.civicNumber)
  if (address.street) ret = ret.concat(` ${address.street}`)
  if (address.city) ret = ret.concat(`, ${address.city}`)
  if (address.stateIso) ret = ret.concat(`, ${address.stateIso}`)
  if (address.country) ret = ret.concat(`, ${address.country}`)
  if (address.postalCode) ret = ret.concat(`, ${address.postalCode?.toUpperCase()}`)
  return ret
}

export const formatPhone = (phoneNumber: string | undefined | null): string => {
  if (!phoneNumber) return 'N/A'
  return `+1 (${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}`
}

export const removeDiacritics = (text: string): string => {
  if (!text.trim()) {
    return text
  }

  const normalizedText = text.normalize('NFD')
  const removedDiacritics = normalizedText.replace(/[\u0300-\u036f]/g, '')
  return removedDiacritics.normalize('NFC')
}
