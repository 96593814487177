import { useNavigate, useParams } from 'react-router-dom'
import { EOriginSystemId, useGetCreditApplicationById } from '@src/api/credit-api'

import { AcceptTermsCheckbox, Loader } from '@src/components'
import BackButton from '@src/components/BackButton'
import { useTranslation } from 'react-i18next'
import { EApplicantType } from '@src/types'

const FlinksPage = (): JSX.Element => {
  const { id, applicantType } = useParams<{ id: string; applicantType: EApplicantType }>()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id as string, false)

  const originSystemId = creditApp?.originSystemId
  const handleContinueToFlinks = (evt: { preventDefault: () => void }) => {
    evt.preventDefault()
    navigate(`/creditapplication/${id}/flinks/${applicantType}/validate`)
  }
  if (isLoadingApp) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return (
    <main id="flinks-how-it-works" className="general-message">
      <form onSubmit={handleContinueToFlinks}>
        <h4 className="subtitle">{t('common.incomeVerification')}</h4>
        <h2>{t('flinks.ValidateIncomeTitle')}</h2>

        <p style={{ margin: '1rem 0' }}>
          <h4>{t('flinks.whatIsFlinks')}</h4>
        </p>

        <div id="flinks-steps-group">
          <div id="flinks-step" className="applicationInfo-card">
            <h3>{t('flinks.firstStep')}</h3>
            <p className="paragraph">{t('flinks.firstStepDescription')}</p>
            <i className="fa-regular fa-building-columns" />
          </div>
          <div id="flinks-step" className="applicationInfo-card">
            <h3>{t('flinks.secondStep')}</h3>
            <p className="paragraph">{t('flinks.secondStepDescription')}</p>
            <i className="fa-regular fa-money-check" />
          </div>
          <div id="flinks-step" className="applicationInfo-card">
            <h3>{t('flinks.thirdStep')}</h3>
            <p className="paragraph">{t('flinks.thirdStepDescription')}</p>
            <i className="fa-sharp fa-regular fa-magnifying-glass-chart" />
          </div>
        </div>
        <AcceptTermsCheckbox msgOnInvalid="common.acceptConditions" id="acceptAuthorise" termId="">
          {i18n.language === 'fr' && (
            <p className="blue-strong">
              J&apos;ai lu et j&apos;accepte les{' '}
              <strong>
                <a href={`/creditapplication/${id}/flinks/${applicantType}/terms`}>conditions générales</a>
              </strong>{' '}
              d&apos;iFinance
            </p>
          )}
          {i18n.language === 'en' && (
            <p className="blue-strong">
              I have read and agree to iFinance&apos;s{' '}
              <strong>
                <a href={`/creditapplication/${id}/flinks/${applicantType}/terms`}>Terms and Conditions</a>
              </strong>
            </p>
          )}
        </AcceptTermsCheckbox>
        <div
          className={`btn-group mobile-reversed ${originSystemId === EOriginSystemId.MerchantDashboard ? 'justify-end' : 'space-between'}`}
        >
          {originSystemId !== EOriginSystemId.MerchantDashboard && <BackButton />}
          <button type="submit" className="btn primary-action-btn btn-blue btn-simple">
            <span>{t('flinks.continueFlinks')}</span>
            <i className="fa-regular fa-arrow-right" />
          </button>
        </div>
      </form>
    </main>
  )
}

export default FlinksPage
