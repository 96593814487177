import * as yup from '@src/types/schemas/common/yup-extended'
import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import { Constants } from '@src/types'

export const buildEditSinAndLoanAmountSchema = (maxLoanAmount: number) => {
  return yup.default.object({
    id: yup.default.string().default(''),
    needsApplicantSin: yup.default.bool(),
    needsCoapplicantSin: yup.default.bool(),
    applicantSin: yup.default.string().nullable(true).when('needsApplicantSin', {
      is: true,
      then: yup.default.string().required().SinType(),
    }),
    coapplicantSin: yup.default.string().nullable(true).when('needsCoapplicantSin', {
      is: true,
      then: yup.default.string().required().SinType(),
    }),
    requestedLoanAmount: yupExtInt.double.required().default(0).min(Constants.MinLoanAmount).max(maxLoanAmount),
  })
}
const EditSinAndLoanAmountSchema = buildEditSinAndLoanAmountSchema(Constants.MaxLoanAmount)

export type EditSinAndLoanAmount = yup.default.InferType<typeof EditSinAndLoanAmountSchema>
