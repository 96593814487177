import { Merchant, useMerchantById } from '@src/api/merchants-api'
import { Loader } from '@src/components'
import { useAppStore } from '@src/data/AppContext'
import { scrollToTop } from '@src/services/utils'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import AddressForm from './components/AddressForm'
import ContactInformationForm from './components/ContactInformationForm'
import EmploymentForm from './components/EmploymentForm'
import LoanAmountForm from './components/LoanAmountForm'
import LoanPurposeForm from './components/LoanPurposeForm'
import PersonalInformationForm from './components/PersonalInformationForm'
import {
  Applicant,
  LoanAmountInput,
  LoanPurposeInput,
  PersonalInformationInput,
  Prequalification,
  ServiceProviderInput,
} from './components/PrequalificationSchema'
import ReviewPrequalification from './components/ReviewPrequalification'
import ServiceProviderForm from './components/ServiceProviderForm'
import StepperFormFooter from './components/StepperFormFooter'
import StepProgressionBar from './components/StepProgressionBar'
import { buildDefaultPrequalification, useConvertedLoanPurposeId, useNewApplicationStepList } from './submit-app-hooks'

type SubmitNewAppFormProps = {
  initialMerchant: Merchant
}
const SubmitNewAppForm = ({ initialMerchant }: SubmitNewAppFormProps) => {
  const { merchantId: urlMerchantId, currentStep } = useParams()
  const appStore = useAppStore()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/${urlMerchantId}/new-app/loan-amount`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [disableNext, setDisableNext] = useState(false)

  // on utilise le merchantId de l'url pour ne pas faire changer la liste de steps quand
  // quand l'utilisateur selectionne un marchand
  const steps = useNewApplicationStepList(urlMerchantId!)

  const cls = `form-section step-${currentStep}-active`

  const [prequalInfo, setPrequalInfo] = useState<Prequalification>(buildDefaultPrequalification(initialMerchant))

  const convertedLoanPurposeId = useConvertedLoanPurposeId(prequalInfo.loanPurposeId)

  useLayoutEffect(scrollToTop, [])

  const gotoStep = useCallback((stepPath: string) => {
    scrollToTop()
    navigate(`/${urlMerchantId}/new-app/${stepPath}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nextStepAfter = useCallback(() => {
    const nextStepIdx = steps.indexOf(currentStep!.toLowerCase()) + 1
    if (nextStepIdx < steps.length) {
      gotoStep(steps[nextStepIdx])
    }
  }, [currentStep, gotoStep, steps])

  const handlePrequalificationUpdated = (
    data: LoanAmountInput | LoanPurposeInput | ServiceProviderInput | PersonalInformationInput,
  ) => {
    if ((data as PersonalInformationInput).applicant) {
      const { applicant, ...otherData } = data as PersonalInformationInput
      setPrequalInfo({ ...prequalInfo, ...otherData, applicant: { ...prequalInfo.applicant, ...applicant } })
    } else {
      const update: Prequalification = {
        ...prequalInfo,
        ...(data as LoanAmountInput | LoanPurposeInput | ServiceProviderInput),
      }
      setPrequalInfo(update)
    }

    if ((data as ServiceProviderInput).merchantId) appStore.setMerchantId((data as ServiceProviderInput).merchantId)
    nextStepAfter()
  }

  const handleApplicantUpdated = (data: Partial<Applicant>) => {
    const update = { ...prequalInfo, applicant: { ...prequalInfo.applicant, ...data } }
    setPrequalInfo(update)
    nextStepAfter()
  }

  return (
    <main className={cls}>
      <StepProgressionBar steps={steps} currentStep={currentStep!} />
      {currentStep === 'loan-amount' && (
        <LoanAmountForm prequalificationData={prequalInfo} onPrequalificationUpdated={handlePrequalificationUpdated} />
      )}
      {currentStep === 'loan-purpose' && (
        <LoanPurposeForm prequalificationData={prequalInfo} onPrequalificationUpdated={handlePrequalificationUpdated} />
      )}
      {currentStep === 'service-provider' && (
        <ServiceProviderForm
          prequalificationData={prequalInfo}
          loanPurposeId={convertedLoanPurposeId}
          onPrequalificationUpdated={handlePrequalificationUpdated}
        />
      )}

      {currentStep === 'personal-information' && (
        <PersonalInformationForm
          prequalificationData={prequalInfo}
          onPrequalificationUpdated={handlePrequalificationUpdated}
          loanPurposeId={prequalInfo.loanPurposeId}
        />
      )}

      {currentStep === 'contact' && (
        <ContactInformationForm onApplicantUpdated={handleApplicantUpdated} applicantData={prequalInfo.applicant} />
      )}

      {currentStep === 'address' && (
        <AddressForm
          applicantData={prequalInfo.applicant}
          onApplicantUpdated={handleApplicantUpdated}
          applicantName={prequalInfo.applicant.firstName}
          setDisableNext={setDisableNext}
        />
      )}

      {currentStep === 'employment' && (
        <EmploymentForm applicantData={prequalInfo.applicant} onApplicantUpdated={handleApplicantUpdated} />
      )}

      {currentStep === 'review' && <ReviewPrequalification gotoStep={gotoStep} prequalification={prequalInfo} />}

      {currentStep !== 'review' && (
        <StepperFormFooter
          currentStepIndex={steps.indexOf(currentStep!) + 1}
          stepLength={steps.length}
          disableBack={steps.indexOf(currentStep!) === 0}
          disableNext={disableNext}
        />
      )}
    </main>
  )
}

const SubmitNewAppPage = () => {
  const urlParams = useParams()
  const urlMerchantId = urlParams.merchantId as string
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [merchant, isFetchingMerchant, error] = useMerchantById(urlMerchantId)

  useEffect(() => {
    if (error) {
      if ((error as AxiosError)?.response?.status === 403) {
        navigate('/get-merchant-error/inactive')
      } else if ((error as AxiosError)?.response?.status === 404) {
        navigate('/get-merchant-error/not-found')
      } else {
        navigate('/general-error-page/error')
      }
    }
  }, [error, navigate])

  if (isFetchingMerchant) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  if (error) return null
  if (merchant) return <SubmitNewAppForm initialMerchant={merchant} />
  return null
}

export default SubmitNewAppPage
