import { FilteredCreditApplication, useRetryHardHit } from '@src/api/credit-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'

type Props = {
  creditApp: FilteredCreditApplication
}

const RetryHardHitButton = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [retryHardHit, isSubmitting] = useRetryHardHit()

  return (
    <AsyncActionButton
      fullWidth
      primary
      isPending={isSubmitting}
      type="button"
      onClick={() => {
        retryHardHit(creditApp.id).catch(reportErrorToConsole)
      }}
    >
      <i className="fa-solid fa-rotate-right" />
      <span>{t('common.retryHardHit')}</span>
    </AsyncActionButton>
  )
}

export default RetryHardHitButton
