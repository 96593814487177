import { ChangeEventHandler, useMemo, useState } from 'react'

export interface RadioButtonListOption {
  id: string
  primaryText: React.ReactNode
  secondaryText?: React.ReactNode
}

interface RadioButtonListProps {
  name: string
  options: RadioButtonListOption[]
  defaultValue?: string | undefined | null
  onChange: ChangeEventHandler<HTMLInputElement>
  onClick?: () => void
  reverse?: boolean
}

const RadioButtonList = ({ name, options, defaultValue, onChange, reverse = false, onClick }: RadioButtonListProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.currentTarget.value
    onChange(e)
    setSelectedValue(newValue)
  }

  const displayedOptions = useMemo(() => (reverse ? [...options].reverse() : options), [options, reverse])

  return (
    <div className="radiobuttons-stacked-cards">
      {displayedOptions.map((option) => (
        <div key={option.id} className="radiobuttons-stacked-card-item">
          <input
            required
            type="radio"
            id={`${name}_${option.id}`}
            name={name}
            checked={selectedValue === option.id}
            value={option.id}
            onClick={onClick}
            onChange={handleChange}
          />
          <label htmlFor={`${name}_${option.id}`}>
            <span className="info-bold">{option.primaryText}</span>
            {option.secondaryText && (
              <>
                {' '}
                <span className="info-gray">{option.secondaryText}</span>
              </>
            )}
          </label>
        </div>
      ))}
    </div>
  )
}

export default RadioButtonList
