import { FilteredCreditApplication } from '@src/api/credit-api'
import { Merchant } from '@src/api/merchants-api'
import { formatDate } from '@src/services/Formatter'
import { EWorksheetStatus } from '@src/types'
import { addDays, addMonths, isLastDayOfMonth, isValid, isWeekend, parse, setDate } from 'date-fns'

const possibleFirstPaymentDates = [1, 15, 23]

const isNotEndOfMonthAndWeekday = (date: Date) => isWeekend(date) && !isLastDayOfMonth(date)

const isHoliday = (date: Date, listHolidays: Date[]) => {
  const formattedDate = formatDate(date)
  return listHolidays?.toString().includes(formattedDate)
}

export const cantSelectDateForActivation = (date: Date, listHolidays: Date[]) => {
  return isHoliday(date, listHolidays) || isNotEndOfMonthAndWeekday(date)
}

export const getClosestDeliveryOn = (date: Date, listHolidays: Date[]): Date => {
  const nowEDT = new Date(date.toLocaleString('en-US', { timeZone: 'America/Toronto' }))

  const hours = nowEDT.getHours()
  if (cantSelectDateForActivation(nowEDT, listHolidays) || hours >= 19) {
    const nextDay = new Date(nowEDT)
    nextDay.setDate(nowEDT.getDate() + 1)
    return getClosestDeliveryOn(nextDay, listHolidays)
  }

  return nowEDT
}

const nthBusinessDayAfterDate = (startDate: Date, nthBusinessDay: number, listHolidays: Date[]) => {
  let n: number = nthBusinessDay
  for (let i = 1; i <= n; i += 1) {
    const date: Date = addDays(startDate, i)
    if (cantSelectDateForActivation(date, listHolidays)) n += 1
  }
  return addDays(startDate, n)
}

const rotateListFromGivenValue = (listToRotate: Array<number>, rotateFrom: number) => {
  const index: number = listToRotate.indexOf(rotateFrom)
  return listToRotate.slice(index, index + listToRotate.length).concat(listToRotate.slice(0, index))
}

const getEarliestPaymentDateGivenActivationDate = (activationDate: Date, listHolidays: Date[]) => {
  const initialEarliestDateForFirstPayment = nthBusinessDayAfterDate(activationDate, 5, listHolidays)
  let earliestDayForFirstPayment: number = initialEarliestDateForFirstPayment.getDate()

  earliestDayForFirstPayment = possibleFirstPaymentDates.find((day: number) => day >= earliestDayForFirstPayment) ?? 0

  if (earliestDayForFirstPayment > 0) {
    return new Date(
      initialEarliestDateForFirstPayment.getFullYear(),
      initialEarliestDateForFirstPayment.getMonth(),
      earliestDayForFirstPayment,
    )
  }

  return new Date(
    initialEarliestDateForFirstPayment.getFullYear(),
    initialEarliestDateForFirstPayment.getMonth() + 1,
    possibleFirstPaymentDates[0],
  )
}

export const computeFirstPaymentDateOptions = (pActivationDate: string, listHolidays: Date[]) => {
  const activationDate = pActivationDate ?? formatDate(new Date())
  const activationDateAsDate = parse(activationDate, 'yyyy-MM-dd', new Date())
  const earliestPaymentDate = getEarliestPaymentDateGivenActivationDate(activationDateAsDate, listHolidays)
  const earliestPaymentDay = earliestPaymentDate.getDate()
  const rotatedListOfOptions = rotateListFromGivenValue(possibleFirstPaymentDates, earliestPaymentDay)
  rotatedListOfOptions.push(rotatedListOfOptions[0])
  const firstPaymentDateOptionsList: Array<Date> = []

  rotatedListOfOptions.forEach((day) => {
    let firstPaymentDate = setDate(earliestPaymentDate, day)
    firstPaymentDate = firstPaymentDateOptionsList.some((date) => date.getTime() > firstPaymentDate.getTime())
      ? addMonths(firstPaymentDate, 1)
      : firstPaymentDate
    firstPaymentDateOptionsList.push(firstPaymentDate)
  })
  return firstPaymentDateOptionsList.filter((option) => isValid(option))
}

export const canAccessWorksheet = (creditApp: FilteredCreditApplication, merchant: Merchant) => {
  return (
    merchant.noPaymentPolicy &&
    creditApp.allIncomesConfirmed &&
    creditApp.worksheet?.status === EWorksheetStatus.Draft &&
    creditApp.allTaskCompletedForCVT
  )
}

export const getWorksheet = (creditApp: FilteredCreditApplication) => {
  if (creditApp.worksheet === null) {
    throw new Error('Worksheet is null')
  }
  return creditApp.worksheet
}
