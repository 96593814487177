import { useGetCreditApplicationById } from '@src/api/credit-api'
import { useAppStore } from '@src/data/AppContext'
import { getIdsFromUrl, isMedicardWebSite } from '@src/services/query-string'
import React from 'react'

export const useCurrentMerchantId = () => {
  const idsFromUrl = getIdsFromUrl()
  const appStore = useAppStore()
  const [creditApp] = useGetCreditApplicationById(idsFromUrl.creditAppId, false)
  const merchantId = creditApp ? creditApp.merchantId : appStore.merchantId

  const parts = merchantId.split('*')
  return parts.length === 2 ? parts[1] : parts[0]
}

const changeTitle = () => {
  document.title = String(process.env.REACT_APP_MEDICARD_MERCHANT_SITE_TITLE)
}

const changeFavicon = () => {
  window?.document
    ?.querySelector(`link[rel~='icon']`)
    ?.setAttribute('href', String(process.env.REACT_APP_MEDICARD_MERCHANT_SITE_FAVICON))
}

export const useInitializePageTitleAndIcon = () => {
  React.useEffect(() => {
    const isMedicardHost = isMedicardWebSite()
    if (isMedicardHost) {
      changeTitle()
      changeFavicon()
    }
  }, [])
}
