import { FormatCurrency, formatPhone } from '@src/services/Formatter'
import { first } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { EJobType } from '@src/types'
import { Applicant } from './PrequalificationSchema'

type Props = {
  applicant: Applicant
  hasApplicantError: boolean
  hasApplicantAddressError: boolean
  hasApplicantRevenueError: boolean
  gotoStep: (target: string) => void
}
const ReviewApplicantInfo = ({
  applicant,
  hasApplicantError,
  hasApplicantAddressError,
  hasApplicantRevenueError,
  gotoStep,
}: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="review-item">
        <h1 className="h3">{t(applicant.isCoapplicant ? 'common.aboutCoapplicant' : 'common.aboutYourself')}</h1>
        <div className="box">
          <div className="box-content">
            <p>
              <strong>{t('personalInformation.preferredLanguage')} : </strong>
              <span>{t(`enum.ePreferredLanguage.${applicant.languageId!}`)}</span>
            </p>
            <p>
              <strong>{t('personalInformation.yourGender')} : </strong>
              <span>{t(`enum.eGenderType.${applicant.genderId!}`)}</span>
            </p>
            <p>
              <strong>{t('personalInformation.yourFirstName')} : </strong>
              <span>{applicant.firstName}</span>
            </p>
            <p>
              <strong>{t('personalInformation.yourLastName')} : </strong>
              <span>{applicant.lastName}</span>
            </p>
            <p>
              <strong>{t('confirmation.birthDate')} : </strong>
              <span>{applicant.birthDate}</span>
            </p>
            <p>
              <strong>{t('confirmation.mobilePhone')} : </strong>
              <span>{formatPhone(applicant.cellPhone)}</span>
            </p>
            {applicant.homePhone && (
              <p>
                <strong>{t('confirmation.homePhone')} : </strong>
                <span>{formatPhone(applicant.homePhone)}</span>
              </p>
            )}
            <p>
              <strong>{t('confirmation.email')} : </strong>
              <span>{applicant.email}</span>
            </p>
            {applicant.sin && (
              <p>
                <strong>{t('personalInformation.sin')} : </strong>
                <span>{applicant.sin}</span>
              </p>
            )}
          </div>
          <button
            type="button"
            className={hasApplicantError ? 'btn-error btn-simple-error' : 'btn-action btn-simple'}
            onClick={() => gotoStep('personal-information')}
          >
            {t('review.modify')}
          </button>
        </div>
      </div>

      <div className="review-item">
        <h1 className="h3">{t('common.yourHome')}</h1>
        <div className="box">
          <div className="box-content">
            <p>
              <strong>{t('confirmation.address')} : </strong>
              <span>
                {applicant.currentAddress.apartment && `${applicant.currentAddress.apartment}-`}
                {applicant.currentAddress.civicNumber} {applicant.currentAddress.street},{' '}
                {applicant.currentAddress.city}, {applicant.currentAddress.stateIso}{' '}
                {applicant.currentAddress.postalCode}
              </span>
            </p>
            <p>
              <strong>{t('common.since')} : </strong>
              {Math.floor(applicant.currentAddress.months / 12) > 0 && (
                <span>
                  {t('common.yearsLabel', {
                    count: Math.floor(applicant.currentAddress.months / 12),
                  })}
                  &nbsp;
                </span>
              )}
              {applicant.currentAddress.months % 12 > 0 && (
                <span>
                  {t('common.monthsLabel', {
                    count: applicant.currentAddress.months % 12,
                  })}
                </span>
              )}
            </p>
            <p>
              <strong>{t('housing.homeFeeType')} : </strong>
              <span>{t(`enum.eHomeFeeType.${applicant.expenses.homeFeeTypeId}`)}</span>
            </p>
          </div>
          <button
            type="button"
            className={hasApplicantAddressError ? 'btn-error btn-simple-error' : 'btn-action btn-simple'}
            onClick={() => gotoStep('address')}
          >
            {t('review.modify')}
          </button>
        </div>
      </div>

      <div className="review-item">
        <h1 className="h3">{t('income.howMuch')}</h1>
        <div className="box">
          <div className="box-content">
            {applicant.currentJobs[0].jobType !== EJobType.Unemployed && (
              <>
                <p>
                  <strong>{t('income.category')} : </strong>
                  <span> {t(`enum.eJobType.${applicant.currentJobs[0].jobType}`)}</span>
                </p>
                <p>
                  <strong>{t('income.jobTitle')} : </strong>
                  <span> {applicant.currentJobs[0].jobTitle}</span>
                </p>
                <p>
                  <strong>{t('income.beforeDeductions')} : </strong>
                  <span> {FormatCurrency(applicant.currentJobs[0].annualSalary)}</span>
                </p>
                <p>
                  <strong>{t('income.employerName')} : </strong>
                  <span> {applicant.currentJobs[0].employerName}</span>
                </p>
                <p>
                  <strong>{t('income.phoneNumber')} : </strong>
                  <span> {formatPhone(applicant.currentJobs[0].employerPhone)}</span>
                </p>
                <p>
                  <strong>{t('income.extension')} : </strong>
                  <span> {applicant.currentJobs[0].employerPhoneExt}</span>
                </p>

                <p>
                  <strong>{t('income.employmentLenght')} : </strong>
                  {applicant.currentJobs[0].years && (
                    <span>
                      {t('common.yearsLabel', {
                        count: applicant.currentJobs[0].years,
                      })}
                      &nbsp;
                    </span>
                  )}
                  {applicant.currentJobs[0].yearMonths && (
                    <span>
                      {t('common.monthsLabel', {
                        count: applicant.currentJobs[0].yearMonths,
                      })}
                    </span>
                  )}
                </p>
              </>
            )}
            <p>
              <strong>{t('income.otherIncome')} : </strong>
              <span> {FormatCurrency(first(applicant.otherIncomes)?.annualAmount)}</span>
            </p>
          </div>
          <button
            type="button"
            className={hasApplicantRevenueError ? 'btn-error btn-simple-error' : 'btn-action btn-simple'}
            onClick={() => gotoStep('employment')}
          >
            {t('review.modify')}
          </button>
        </div>
      </div>
    </>
  )
}

export default ReviewApplicantInfo
