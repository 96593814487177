import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import RadioButtonList from '@src/components/RadioButtonList'
import { formatDate } from '@src/services/Formatter'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { useEffect } from 'react'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'
import { useComputeFirstPaymentDateOptions } from '../hooks'

type Props = {
  soonestDeliveryOn: Date
  listHolidays: Date[]
  setStepState: StepStateUpdater
  setNext: () => void
}

const FirstPaymentStep = ({ soonestDeliveryOn, listHolidays, setStepState, setNext }: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext<WorksheetForm>()

  const {
    field: { value, onChange, onBlur, ref: _ref, ...field },
    fieldState: { invalid },
  } = useController({ name: 'firstPaymentOn', control })

  useEffect(() => {
    setStepState({ invalid })
    return () => setStepState({ invalid: false }) // le setNext skip l'update de state donc pour être certains il change sur le onDestroy.
  }, [invalid, setStepState])

  const firstPaymentDateOptions = useComputeFirstPaymentDateOptions(formatDate(soonestDeliveryOn), listHolidays)

  return (
    <>
      <h3 style={{ marginBottom: '16px' }}>{t('worksheet.firstPayment.title')}</h3>
      <p>{t('worksheet.firstPayment.description')}</p>
      <div className="paragraph">
        <div className="radiobuttons-stacked-container">
          <RadioButtonList
            {...field}
            onChange={(e) => {
              onChange(e.currentTarget.value)
              onBlur()
              setNext()
            }}
            onClick={() => {
              onBlur()
              setNext()
            }}
            defaultValue={value?.toString()}
            options={firstPaymentDateOptions}
          />
        </div>
      </div>
    </>
  )
}

export default FirstPaymentStep
