import { useUploadFiles } from '@src/api/credit-api'
import { UploadFilesDto } from '@src/api/upload-files-api'
import { reportErrorToServer } from '@src/services/error-logger'
import { getRecaptchaToken } from '@src/services/recaptcha'
import { Constants, CustomError, EApplicantType } from '@src/types'
import React, { Dispatch } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

type Props = {
  applicantType: EApplicantType
  typeId: number
  subKey: string | null
  creditApplicationId: string
  disabled?: boolean
  id: string
  setError: Dispatch<CustomError>
}

const UploadFileButton = ({
  applicantType,
  creditApplicationId,
  typeId,
  subKey,
  disabled = false,
  id,
  setError,
}: Props) => {
  const { t } = useTranslation()
  const MAX_FILE_SIZE = 10485760 // 10MB
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [uploadFile, isUploading, reset] = useUploadFiles()

  const icon = isUploading ? <div className="mini-spinner-white" /> : <i className="fa-solid fa-arrow-up" />

  const onChangeFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files.length === 0) return

    try {
      const recaptchaToken = await getRecaptchaToken(executeRecaptcha, 'submit_document')

      const dto: UploadFilesDto = {
        creditApplicationId,
        applicantType,
        typeId,
        subKey,
        files: Array.from(files),
        recaptcha: recaptchaToken,
      }

      const hasError = dto.files.some((file) => {
        if (file.size > MAX_FILE_SIZE) {
          const customError: CustomError = new Error(t(`common.errors.fileSizeTooBig`))
          customError.id = id
          setError(customError)
          return true
        }

        const fileNameSplit = file.name.split('.').pop() ?? ''
        const supportedExtension = Constants.SupportedDocumentTypes.includes(fileNameSplit)

        if (!supportedExtension) {
          const customError: CustomError = new Error(t(`common.errors.fileTypeNotSupported`))
          customError.id = id
          setError(customError)
          return true
        }

        return false
      })

      if (hasError) {
        return
      }

      await uploadFile(dto)
      reset()
    } catch (error) {
      reportErrorToServer(error as Error)
    }
  }

  return (
    <label className="icon-btn" htmlFor={id}>
      <span style={{ color: 'white', padding: '6px' }}>{icon}</span>
      <input
        id={id}
        style={{ display: 'none' }}
        type="file"
        accept={Constants.SupportedDocumentTypes}
        onChange={onChangeFileSelected}
        disabled={isUploading || disabled}
        multiple
      />
    </label>
  )
}

export default UploadFileButton
