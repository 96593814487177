import { EContractStatus, useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import { getStringFromQueryString } from '@src/services/query-string'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

const ConsignoCallBack = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(id!, false)

  const status = getStringFromQueryString(searchParams, 'status')

  const contractReceived = [EContractStatus.UnderReview, EContractStatus.Completed, EContractStatus.Declined].includes(
    creditApp?.contract?.status as EContractStatus,
  )

  useEffect(() => {
    if (!creditApp && !isFetchingCreditApp) navigate('', { replace: true })
    else if (creditApp && !contractReceived && !status && !isFetchingCreditApp)
      navigate(`/creditapplication/${id}`, { replace: true })
  }, [contractReceived, creditApp, id, isFetchingCreditApp, navigate, status])

  if ((status && creditApp) || contractReceived)
    return (
      <main id="flinks-validation" className="general-message">
        <div style={{ alignSelf: 'center', textAlign: 'center' }}>
          <h1 style={{ marginBottom: '1rem' }}>{t('common.thankYou')}</h1>
          <h3>{t('contract.received')}</h3>
          <h3>{t('contract.review')}</h3>

          <div className="btn-group full-width-mobile">
            <Link
              style={{ gap: '2rem', minWidth: '0', justifyContent: 'center' }}
              to={`/creditapplication/${id}`}
              className="btn btn-blue"
            >
              <i className="fa-regular fa-arrow-left" />
              <span>{t('flinks.back')}</span>
            </Link>
          </div>
        </div>
      </main>
    )

  return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
}

export default ConsignoCallBack
