import { useGetContractIframeUrl } from '@src/api/contract-api'
import { EContractStatus, useGetCreditApplicationById } from '@src/api/credit-api'
import { ErrorPage, Loader } from '@src/components'
import { EWorksheetStatus } from '@src/types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { contractIsRecerved } from '../ViewCreditApplication/HardHitApprovedResultPage/hooks'

type Props = {
  creditAppId: string
}

const ContractWaitingPage = ({ creditAppId }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [shouldPollCreditApp, setShouldPollCreditApp] = useState(true)
  const [shouldPollContract, setShouldPollContract] = useState(false)
  const [creditApp] = useGetCreditApplicationById(creditAppId, shouldPollCreditApp, 15000)
  const [iframeDto, _isLoadingLink, failureCount] = useGetContractIframeUrl(creditAppId, shouldPollContract)

  if (
    creditApp &&
    (creditApp.worksheet?.status === EWorksheetStatus.Draft || contractIsRecerved(creditApp.contract.status))
  ) {
    navigate('..')
  }

  useEffect(() => {
    if (creditApp?.contract.status === EContractStatus.Ready) {
      setShouldPollCreditApp(false)
      setShouldPollContract(true)
    }
  }, [creditApp?.contract.status])

  const urlsDto = useMemo(() => iframeDto?.urls?.find((x) => x.isForApplicant === true), [iframeDto?.urls])

  useEffect(() => {
    if (urlsDto?.url) window.location.href = urlsDto.url
  }, [navigate, urlsDto?.url])

  if (failureCount === 6) return <ErrorPage title={t('contract.errorMessage')} />

  return <Loader title={t('contract.waiting')} description={t('contract.waitingDescription')} />
}

export default ContractWaitingPage
