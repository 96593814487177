import { EContractStatus, FilteredCreditApplication } from '@src/api/credit-api'
import { CustomerFeedback } from '@src/api/feedback-api'
import { EApplicantType, EExternalStep, ERequiredExternalStepStatus, RequiredDocumentWithStatus } from '@src/types'
import {
  hasApplicantSubmittedAllRequiredIncomeDocuments,
  hasApplicantSubmittedFlinks,
} from '@src/data/credit-application-selectors'
import { useMemo, useState } from 'react'
import { useGetHolidaysForYear } from '@src/api/holiday-api'
import { getClosestDeliveryOn } from '@src/containers/Worsheet/selectors'
import { compareAsc } from 'date-fns'
import { CreditStepStatus } from './OverviewPage/components/CreditStep'

const contractIsReceivedStatusSet = new Set([
  EContractStatus.UnderReview,
  EContractStatus.Completed,
  EContractStatus.Declined,
])

export function contractIsRecerved(status: EContractStatus) {
  return contractIsReceivedStatusSet.has(status)
}

export function hasCompletedFlinks(creditApp: FilteredCreditApplication | null): boolean {
  const found = creditApp?.requiredExternalSteps?.find(
    (x) => x.applicantType === EApplicantType.Applicant && x.externalStepId === EExternalStep.BankAccount,
  )

  if (found) {
    return (
      found.status === ERequiredExternalStepStatus.WaitingForBank ||
      found.status === ERequiredExternalStepStatus.Completed
    )
  }
  return false
}

export function isReadyForCustomerFeedback(
  creditApp: FilteredCreditApplication | null,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  if (!creditApp) return false
  return (
    creditApp.allIncomesConfirmed ||
    hasApplicantSubmittedFlinks(creditApp, EApplicantType.Applicant) ||
    hasApplicantSubmittedAllRequiredIncomeDocuments(skipBankAccountRequiredDocuments, EApplicantType.Applicant)
  )
}

export function getFeedbackStepStatus(
  creditApp: FilteredCreditApplication | null,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
  feedback: CustomerFeedback | null,
) {
  if (!isReadyForCustomerFeedback(creditApp, skipBankAccountRequiredDocuments)) return CreditStepStatus.NotReady
  if (!feedback) return CreditStepStatus.RequiresAttention
  return CreditStepStatus.Completed
}

export const useIsReportExpired = (hardHitReportReceivedOn: Date) => {
  const [currentDate] = useState(new Date())
  const [listHolidays] = useGetHolidaysForYear(currentDate.getFullYear())

  const isExpired = useMemo(() => {
    const soonestDeliveryOn = getClosestDeliveryOn(currentDate, listHolidays)
    const receivedOnPlus90Days = new Date(hardHitReportReceivedOn)
    receivedOnPlus90Days.setDate(receivedOnPlus90Days.getDate() + 90)

    return compareAsc(soonestDeliveryOn, receivedOnPlus90Days) === 1
  }, [currentDate, listHolidays, hardHitReportReceivedOn])

  return isExpired
}
