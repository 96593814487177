import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & { href?: string }

const BackButton = ({ href, ...otherProps }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <button
      type="button"
      {...otherProps}
      className="btn secondary-action-btn btn-simple"
      onClick={() => (href ? navigate(href) : navigate(-1))}
    >
      <i className="fa-light fa-arrow-left" />
      <span>{t('common.back')}</span>
    </button>
  )
}

export default BackButton
