import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = { title?: string; description?: string }

const Loader = ({ title, description }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="loader-wrap">
      <div className="loader-box">
        <div className="loader" />
        <h3>{title ?? t('common.withYouShortly')}</h3>
        <div className="paragraph">
          <p>{description ?? t('common.pleaseWait')}</p>
        </div>
      </div>
    </div>
  )
}

export default Loader
