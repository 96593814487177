import { FilteredCreditApplication } from '@src/api/credit-api'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useMerchantById } from '@src/api/merchants-api'
import { ErrorPage, Loader } from '@src/components'
import { EWorksheetStatus } from '@src/types'
import { DEFAULT_STEP, EWorksheetStep, EWorksheetStepList } from './hooks'
import WorksheetStepper from './WorksheetStepper'
import { canAccessWorksheet, getWorksheet } from './selectors'

interface Props {
  creditApp: FilteredCreditApplication
}

const WorksheetPage = ({ creditApp }: Props) => {
  const { step } = useParams<{ step: EWorksheetStep }>()
  const navigate = useNavigate()
  const [merchant, isLoadingMerchant] = useMerchantById(creditApp.merchantId)

  const worksheet = getWorksheet(creditApp)

  if (!step || !EWorksheetStepList.includes(step)) return <Navigate to={`../worksheet/${DEFAULT_STEP}`} replace />
  if (isLoadingMerchant) return <Loader />
  if (!merchant) return <ErrorPage />
  if (!canAccessWorksheet(creditApp, merchant)) {
    if (creditApp.worksheet?.status !== EWorksheetStatus.Draft) return <Navigate to="../contract" replace />
    return <Navigate to=".." replace />
  }

  return (
    <WorksheetStepper
      merchant={merchant}
      creditApp={creditApp}
      setStep={(s) => navigate(`../worksheet/${s}`, { replace: true })}
      step={step}
      worksheet={worksheet}
    />
  )
}

export default WorksheetPage
