import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isPending?: boolean
  children?: React.ReactNode
  withConfirm?: boolean
  primary?: boolean
  fullWidth?: boolean
  keepText?: boolean
}

const AsyncActionButton = ({
  isPending = false,
  disabled,
  children,
  withConfirm = false,
  className,
  primary = false,
  keepText = false,
  onClick,
  fullWidth = false,
  ...otherProps
}: Props) => {
  const { t } = useTranslation()
  const baseClass = primary ? 'btn primary-action-btn btn-blue btn-simple' : 'btn secondary-action-btn btn-simple'
  const cls = className ? `${baseClass} ${className}` : baseClass
  const clsConfirm = className ? `btn primary-action-btn ${className}` : 'btn alert alert-error'

  const [showConfirm, setShowConfirm] = useState<NodeJS.Timeout | null>(null)

  const resetButton = useCallback(() => {
    setShowConfirm(null)
  }, [])

  const onProxyClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (withConfirm) {
        const timeoutId = setTimeout(resetButton, 3000)
        setShowConfirm(timeoutId)
      } else if (onClick) {
        onClick(e)
      }
    },
    [onClick, resetButton, withConfirm],
  )

  const confirmProps = { ...otherProps, onClick }

  return (
    <>
      {(isPending || !showConfirm) && (
        <button
          style={fullWidth ? { width: '100%' } : {}}
          className={cls}
          {...otherProps}
          onClick={onProxyClick}
          disabled={isPending || disabled}
        >
          {(!isPending || keepText) && children}
          {isPending && <div className="mini-spinner" />}
        </button>
      )}
      {!isPending && showConfirm && (
        <button className={clsConfirm} {...confirmProps}>
          {t('common.confirm')}
        </button>
      )}
    </>
  )
}

export default AsyncActionButton
