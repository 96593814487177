import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { ManageCoappButton } from '@src/components'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApp: FilteredCreditApplication
}

const DeclinedPrequalForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  useTrackStepReachedEvent(creditApp, ETrackedEvents.PrequalDeclined)

  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.financeApplication')}</h4>
      <form>
        <h3>{t('common.refused', { forStep: t('common.prequalified') })}</h3>
        <div className="btn-group justify-end">
          <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={creditApp.coapplicant !== null} />
        </div>
      </form>
    </main>
  )
}

export default DeclinedPrequalForm
