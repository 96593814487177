import { EMPTY_ARRAY, RequiredDocument } from '@src/types'
import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'skip-bank-request'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

const getSkipBankRequestRequirements = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await apiClient.get<RequiredDocument[]>(
    `/api/creditApplication/${encodeURIComponent(id)}/skipbankrequestrequirements`,
  )
  return response.data
}

export default function useGetSkipBankRequestRequirements(creditApplicationId: string): [RequiredDocument[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getSkipBankRequestRequirements,
    enabled: !!creditApplicationId,
  })

  return [data ?? EMPTY_ARRAY, isFetching]
}
