import { FilteredCreditApplication } from '@src/api/credit-api'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApp: FilteredCreditApplication
}
const CompletedAppPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const msg = creditApp.loanCreated ? 'common.loanCreated' : 'common.awaitingContractReview'
  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.goodNews')}</h4>
      <div>
        <h3>{t(msg)}</h3>
      </div>
    </main>
  )
}

export default CompletedAppPage
