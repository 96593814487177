import {
  Applicant,
  ApplicantJob,
  ApplicantOtherIncome,
  Expenses,
  Prequalification,
} from '@src/containers/SubmitNewApp/components/PrequalificationSchema'
import { EHomeFeeType, EJobType, ELoanPurpose } from '@src/types'

function transformLoanPurposeId(loanPurposeId: ELoanPurpose): ELoanPurpose {
  if (loanPurposeId === ELoanPurpose.HomeImprovement || loanPurposeId === ELoanPurpose.Other) {
    return ELoanPurpose.GoodsAndServices
  }
  return loanPurposeId
}

function transformMerchantId(merchantId: string): string {
  if (!merchantId.startsWith('alis*')) return `alis*${merchantId}`
  return merchantId
}

function transformCurrentJobs(jobs: ApplicantJob[]): ApplicantJob[] {
  if (jobs.length > 0) return jobs.filter((x) => x.jobType !== null && x.jobType !== EJobType.Unemployed)
  return jobs
}

function transformOtherIncomes(other: ApplicantOtherIncome[]): ApplicantOtherIncome[] {
  if (other.length > 0) return other.filter((x) => x.typeId)
  return other
}

export function transformApplicantExpensesToApi(expenses: Expenses): Expenses {
  let ret = { ...expenses }

  Object.entries(ret).forEach(([key, value]) => {
    if (!value && value !== 0) {
      ret = { ...expenses, [key]: null }
    }
  })

  if (ret.homeFeeTypeId === EHomeFeeType.OwnWithMortgage) {
    ret.mortgageMonthly = expenses.housingMonthly
    ret.rentMonthly = null
  } else {
    ret.rentMonthly = expenses.housingMonthly
    ret.mortgageMonthly = null
  }

  return ret
}

export function transformApplicantToApi(applicant: Applicant) {
  return {
    ...applicant,
    expenses: transformApplicantExpensesToApi(applicant.expenses),
    otherIncomes: transformOtherIncomes(applicant.otherIncomes),
    currentJobs: transformCurrentJobs(applicant.currentJobs),
  } as Applicant
}

export function transformPrequalToApi(prequal: Prequalification): Prequalification {
  const toPrequalifyCreditApplication = {
    ...prequal,
    loanPurposeId: transformLoanPurposeId(prequal.loanPurposeId),
    merchantId: transformMerchantId(prequal.merchantId),
    applicant: transformApplicantToApi(prequal.applicant),
  } as Prequalification

  return toPrequalifyCreditApplication
}
