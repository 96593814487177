import { EMPTY_ARRAY } from '@src/types'
import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import apiClient from './api-client'

const keysFactory = {
  cancelReasons: () => [{ scope: 'cancel-reasons' }] as const,
  badReviewReasons: () => [{ scope: 'bad-review-reasons' }] as const,
}

export type ValueListItemDto = {
  value: string
  textFr: string
  textEn: string
  priority?: number
  active: boolean
  contactClient: boolean
}
export type ValueListDto = {
  id: string
  sortMethod: 'priority' | 'alpha'
  defaultvalue: string | null
  items: ValueListItemDto[]
  expiresOn: number
}

export type ValueListItem = {
  id: string
  text: string
  contactClient: boolean
}

function buildValueList(list: ValueListDto | undefined, lang: string) {
  if (!list) return EMPTY_ARRAY

  const inputList: ValueListItemDto[] = [...list.items]
  if (list.sortMethod === 'priority') inputList.sort((x) => x.priority ?? 0)

  const items: ValueListItem[] = inputList.map((x) => {
    return {
      id: x.value,
      text: lang === 'fr' ? x.textFr : x.textEn,
      contactClient: x.contactClient,
    }
  })
  if (list.sortMethod === 'alpha') items.sort((one, two) => (one.text > two.text ? -1 : 1))
  return items
}

const getCancelReasons = async ({
  queryKey: [_],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['cancelReasons']>>) => {
  const response = await apiClient.get<ValueListDto>('api/GetWithdrawalReasons')

  return response.data
}

export function useCancelReasons(lang: string): [ValueListItem[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.cancelReasons(),
    queryFn: getCancelReasons,
  })

  const list = useMemo(() => {
    return buildValueList(data, lang)
  }, [data, lang])

  return [list, isFetching]
}

const getBadReviewReasons = async ({
  queryKey: [_],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['badReviewReasons']>>) => {
  const response = await apiClient.get<ValueListDto>('api/GetBadReviewReasons')
  return response.data
}

export function useBadReviewReasons(lang: string): [ValueListItem[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.badReviewReasons(),
    queryFn: getBadReviewReasons,
  })

  const list = useMemo(() => {
    return buildValueList(data, lang)
  }, [data, lang])
  return [list, isFetching]
}
