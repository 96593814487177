import { IFINANCE_ID, MEDICARD_ID } from '@src/data/merchant-selectors'

export function getStringArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: string[] = [],
): string[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',')
}

export function getNumberArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: number[] = [],
): number[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',').map((x) => +x)
}

export function getNumberFromQueryString(urlParams: URLSearchParams, paramName: string, defaultValue: number): number {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : +value
}

export function getStringFromQueryString(urlParams: URLSearchParams, paramName: string, defaultValue = ''): string {
  return decodeURIComponent(urlParams.get(paramName) ?? defaultValue)
}

export const serializeParameter = (params: Record<string, string | number | string[] | number[]> | undefined) => {
  const searchParams = new URLSearchParams()
  if (params)
    Object.keys(params).forEach((key) => {
      const v = params[key]
      if (v.toString()) searchParams.append(key, v.toString())
    })
  return searchParams.toString()
}

export const isMedicardWebSite = () => {
  return window.location.hostname.includes('medicard.com')
}

export function getDefaultMerchantIdFromHostname() {
  if (isMedicardWebSite()) return MEDICARD_ID

  return IFINANCE_ID
}

export function getIdsFromUrl() {
  const ret = {
    creditAppId: '',
    merchantId: getDefaultMerchantIdFromHostname(),
  }

  const pathParts = window.location.pathname.split('/').filter((x) => !!x)
  const isCreditApp = pathParts.length >= 2 && pathParts[0].toLocaleLowerCase() === 'creditapplication'

  if (isCreditApp) {
    ret.creditAppId = pathParts[1]
  } else if (pathParts.length > 0) {
    ret.merchantId = pathParts[0]
  }

  return ret
}
