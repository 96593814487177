import React from 'react'
import { isMedicardWebSite } from '@src/services/query-string'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { IFINANCE_ID, MEDICARD_ID } from '@src/data/merchant-selectors'
import { useMerchantById } from '@src/api/merchants-api'

const WelcomePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let { merchantId } = useParams()

  if (!merchantId) {
    merchantId = isMedicardWebSite() ? MEDICARD_ID : IFINANCE_ID
  }

  // preloads the merchant
  useMerchantById(merchantId)

  return (
    <main className="step-0">
      <h4 className="subtitle">{t('welcome.applyInMinutes')}</h4>
      <h1>{t('welcome.prequalifiedRapidly')}</h1>
      <div className="paragraph">
        <p>{t('welcome.answerQuestions')}</p>
      </div>
      <button
        id="btnBegin"
        type="button"
        onClick={() => navigate(`/${merchantId}/new-app/loan-amount`)}
        className="btn btn-blue"
      >
        <span>{t('welcome.getStarted')}</span>
        <i className="fa-regular fa-arrow-right" />
      </button>
    </main>
  )
}

export default WelcomePage
