import { reportErrorToServer } from './error-logger'

export const getRecaptchaToken = async (
  executeRecaptcha: ((action?: string) => Promise<string>) | undefined,
  action?: string,
): Promise<string> => {
  const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED
  if (recaptchaEnabled?.toString() === 'true') {
    if (!executeRecaptcha) {
      const err = new Error('ReCAPTCHA non disponible.')
      reportErrorToServer(err)
      throw err
    }

    const recaptchaToken = await executeRecaptcha(action)

    return recaptchaToken
  }

  return ''
}

export default { getRecaptchaToken }
