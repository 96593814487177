import { useTranslation } from 'react-i18next'

const AgentContactPage = () => {
  const { t } = useTranslation()
  return (
    <main className="form-section">
      <div style={{ textAlign: 'center', marginTop: '30%' }}>
        <h3>{t('customerFeedback.customerConfirmation')}</h3>
      </div>
    </main>
  )
}

export default AgentContactPage
