import { EContractStatus, FilteredCreditApplication } from '@src/api/credit-api'
import {
  applicantFullName,
  areAllDocumentsReadyForCVT,
  hasMissingDocuments,
} from '@src/data/credit-application-selectors'
import { Trans, useTranslation } from 'react-i18next'
import { Constants, EWorksheetStatus } from '@src/types'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useRequiredDocumentsWithStatus } from '@src/data/credit-application-hooks'
import { useGetContractIframeUrl } from '@src/api/contract-api'
import { Link } from 'react-router-dom'
import CreditStep, { CreditStepStatus } from './CreditStep'
import { contractIsRecerved, useIsReportExpired } from '../../hooks'

type Props = {
  creditApp: FilteredCreditApplication
}

const WorksheetCreditStep = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const reportExpired = useIsReportExpired(creditApp.hardHitReportReceivedOn)
  const requiredDocumentsWithStatus = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)
  const isContractReady = creditApp.contract.status === EContractStatus.Ready
  const [shouldPoll, setShouldPoll] = useState(isContractReady)

  const contractIsReceived = contractIsRecerved(creditApp.contract.status)

  const [iframeDto, isLoadingLink, failureCount] = useGetContractIframeUrl(creditApp.id, shouldPoll)

  useEffect(() => {
    if (iframeDto || failureCount >= 4) setShouldPoll(false)
  }, [iframeDto, failureCount])

  const hideLinkToDetail =
    reportExpired ||
    contractIsReceived ||
    isLoadingLink ||
    Boolean(iframeDto?.completedSignatures?.find((x) => x.isForApplicant === true))

  const { status, content, link } = useMemo<{ status: CreditStepStatus; content?: ReactNode; link?: string }>(() => {
    if (hasMissingDocuments(creditApp)) return { status: CreditStepStatus.NotReady }

    if (reportExpired) {
      return {
        status: CreditStepStatus.RequiresAttention,
        link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
        content: (
          <Trans
            i18nKey="overview.contract90Days"
            values={{
              phoneNumber: Constants.PhoneNumber,
            }}
            components={{
              a: <Link to={Constants.PhoneNumberLink} style={{ textWrap: 'nowrap', textDecoration: 'underline' }} />,
            }}
          />
        ),
      }
    }
    if (
      !creditApp.allIncomesConfirmed ||
      !creditApp.allTaskCompletedForCVT ||
      !areAllDocumentsReadyForCVT(requiredDocumentsWithStatus)
    )
      return { status: CreditStepStatus.Await, content: t('overview.worksheetWaitContract') }

    if (contractIsRecerved(creditApp.contract.status)) return { status: CreditStepStatus.Completed }

    if (hideLinkToDetail) {
      return {
        status: CreditStepStatus.Completed,
        content: t('worksheet.waitingContract'),
      }
    }

    return {
      status: CreditStepStatus.RequiresAttention,
      content: t('overview.worksheetContent'),
      link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
    }
  }, [creditApp, reportExpired, hideLinkToDetail, requiredDocumentsWithStatus, t])

  return (
    <CreditStep
      status={status}
      linkToDetail={link ?? ''}
      title={t('worksheet.title', { name: applicantFullName(creditApp.applicant) })}
      content={content ?? ''}
      hideLinkToDetail={hideLinkToDetail}
    />
  )
}

export default WorksheetCreditStep
