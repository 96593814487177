import i18n from '@src/api/i18n-api'
import { Merchant } from '@src/api/merchants-api'
import { isDefaultMerchant, isIFinanceMerchant, isMedicardMerchant } from '@src/data/merchant-selectors'
import { getDefaultMerchantIdFromHostname } from '@src/services/query-string'
import { ELoanPurpose } from '@src/types'
import { useMemo } from 'react'
import { Prequalification, PrequalificationSchema } from './components/PrequalificationSchema'

const STEP_LIST_FOR_APPLICANT = ['personal-information', 'contact', 'address', 'employment', 'review']
export function useStepListForApplicant() {
  return STEP_LIST_FOR_APPLICANT
}

export function useNewApplicationStepList(merchantId: string) {
  return useMemo(() => {
    const ret = [...STEP_LIST_FOR_APPLICANT]
    if (isDefaultMerchant(merchantId)) {
      ret.unshift('service-provider')
    }

    if (isIFinanceMerchant(merchantId)) {
      ret.unshift('loan-purpose')
    }

    ret.unshift('loan-amount')
    return ret
  }, [merchantId])
}

export const useConvertedLoanPurposeId = (loanPurposeId: ELoanPurpose) =>
  useMemo(() => {
    if (loanPurposeId === ELoanPurpose.HomeImprovement || loanPurposeId === ELoanPurpose.Other) {
      return ELoanPurpose.GoodsAndServices
    }
    return loanPurposeId
  }, [loanPurposeId])

export const buildDefaultPrequalification = (merchant: Merchant): Prequalification => {
  const defaultPrequal: Prequalification = {
    ...(PrequalificationSchema.getDefault() as Prequalification),
    origination: null,
  }

  if (isDefaultMerchant(merchant.id))
    defaultPrequal.loanPurposeId = isMedicardMerchant(getDefaultMerchantIdFromHostname())
      ? ELoanPurpose.Medical
      : ('' as ELoanPurpose)
  else defaultPrequal.loanPurposeId = merchant.serviceCategory

  defaultPrequal.merchantId = merchant.id
  defaultPrequal.applicant.isCoapplicant = false
  defaultPrequal.applicant.languageId = i18n.language === 'fr' ? 0 : 1

  return defaultPrequal
}

export const buildDefaultCoapplicant = (): Prequalification => {
  const defaultPrequal = PrequalificationSchema.getDefault() as Prequalification
  defaultPrequal.applicant.isCoapplicant = true
  defaultPrequal.applicant.languageId = i18n.language === 'fr' ? 0 : 1
  return defaultPrequal
}
